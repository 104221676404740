/*import React from "react";
import styled from "styled-components/macro";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  background-color: rgba(255,255,255,.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
`;

function Loader() {
  return (
    <Root>
      <div>
        <img src="/static/img/LoaderInfinity.gif" alt="Loader" />
      </div>

    </Root>
  );
}

export default Loader;*/

import React from "react";
import styled from "styled-components/macro";

import { CircularProgress } from "@material-ui/core";

const Root = styled.div `
justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  background-color: rgba(255,255,255,.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
`;

function Loader() {
    return ( < Root >
        <
        CircularProgress m = { 2 }
        style = {
            { color: "#ea983e " }
        }
        /> </Root >
    );
}

export default Loader;