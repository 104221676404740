import React from "react";
import async from "../components/Async";
import { BookOpen, Briefcase, Calendar as CalendarIcon, Map, Sliders, Users, Star, Folder, LogOut, Flag, Trash2, UserPlus, PieChart, Search } from "react-feather";


// Guards
const AuthGuard = async(() =>
    import ("../components/AuthGuard"));

// Auth components
const SignIn = async(() =>
    import ("../pages/auth/SignIn"));
const SignUp = async(() =>
    import ("../pages/auth/SignUp"));
const ResetPassword = async(() =>
    import ("../pages/auth/ResetPassword"));
const Page404 = async(() =>
    import ("../pages/auth/Page404"));
const Page500 = async(() =>
    import ("../pages/auth/Page500"));

// Components components


// Dashboards components
const Default = async(() =>
    import ("../pages/dashboards/Default"));


//Clinet
const ClientList = async(() =>
    import ("../pages/clients/ClientList"));
const ClientAdd = async(() =>
    import ("../pages/clients/ClientAdd"));
const ClientDetails = async(() =>
    import ("../pages/clients/ClientDetails"));
const ClientProfile = async(() =>
    import ("../pages/clients/ClientProfile"));
const ClientMyInfo = async(() =>
    import ("../pages/clients/ClientMyInfo"));

//Referral
const ReferralList = async(() =>
    import ("../pages/referral/ReferralList"));
const ReferralAdd = async(() =>
    import ("../pages/referral/ReferralAdd"));
const ReferralDetails = async(() =>
    import ("../pages/referral/ReferralDetails"));
//Case Manager
const CaseList = async(() =>
    import ("../pages/case/CaselList"));
const CaseAdd = async(() =>
    import ("../pages/case/CaseAdd"));
const CaseDetails = async(() =>
    import ("../pages/case/CaseDetails"));
//Maps
const Maps = async(() =>
    import ("../pages/map_directory/Maps"));

//Maps
const MapsList = async(() =>
    import ("../pages/map_directory/MapsList"));

// Protected routes
const ProtectedPage = async(() =>
    import ("../pages/protected/ProtectedPage"));

//Company Profile
const ComapnyProfile = async(() =>
    import ("../pages/company_profile/CompanyProfile"));

//Organzation
const OrganizationList = async(() =>
    import ("../pages/organization/OrganizationList"));
const OrganizationAdd = async(() =>
    import ("../pages/organization/OrganizationAdd"));

//Service
const ServiceAdd = async(() =>
    import ("../pages/service/ServiceAdd"));
const ServiceList = async(() =>
    import ("../pages/service/ServiceList"));

//Event Calendar
const EventCalendar = async(() =>
    import ("../pages/calendar/Calendar"));

//Event Category
const EventCategoryAdd = async(() =>
    import ("../pages/eventcategory/EventCategoryAdd"));
const EventCategoryList = async(() =>
    import ("../pages/eventcategory/EventCategoryList"));

//Event
const EventAdd = async(() =>
    import ("../pages/event/EventAdd"));
const EventList = async(() =>
    import ("../pages/event/EventList"));
const EventDetails = async(() =>
    import ("../pages/event/EventDetails"));
//Konwledge base Category
const KnowledgebaseCategoryList = async(() =>
    import ("../pages/knowledgebasecategory/KnowledgebaseCategoryList"));
const KnowledgebaseCategoryAdd = async(() =>
    import ("../pages/knowledgebasecategory/KnowledgebaseCategoryAdd"));

//Konwledge base Admin
const KnowledgebaseList = async(() =>
    import ("../pages/knowledgebase/KnowledgebaseList"));
const KnowledgebaseAdd = async(() =>
    import ("../pages/knowledgebase/KnowledgebaseAdd"));


//Konwledge base Faqs
const FaqsList = async(() =>
    import ("../pages/faqs/FaqsList"));
const FaqsCategory = async(() =>
    import ("../pages/faqs/FaqsCategory"));
const FaqsDetails = async(() =>
    import ("../pages/faqs/FaqsDetails"));

//Konwledge base Resources
const ResourcesList = async(() =>
    import ("../pages/resources/ResourcesList"));
const ResourcesDetails = async(() =>
    import ("../pages/resources/ResourcesDetails"));

//User Activity Logs
const UserActivityLogs = async(() =>
    import ("../pages/logs//UserActivityLogs"));
const Trash = async(() =>
    import ("../pages/trash/Trash"));

//File
const File = async(() =>
    import ("../pages/file_manager/File"));


//Logout
const LogOff = async(() =>
    import ("../pages/logoff/LogOff"));



const indexRoutes = {
    id: "Index",
    path: "/",
    icon: < Sliders / > ,
    component: Default,
    permission: [],
    children: null
};

const dashboardsRoutes = {
    id: "Dashboard",
    path: "/dashboard",
    icon: < PieChart / > ,
    component: Default,
    children: null,
    permission: ["dashboard:view"],
    alternatePaths: ['/dashboard']
};
const clientListRoutes = {
    id: "Clients",
    path: "/clients/list",
    icon: < Users / > ,
    component: ClientList,
    children: null,
    permission: ["client:list"],
    alternatePaths: ['/clients/list', '/clients/add', '/clients/details/', '/clients/profile/']
};
const clientAddRoutes = {
    id: "Clients Add",
    path: "/clients/add",
    icon: < Users / > ,
    component: ClientAdd,
    permission: ["client:add"],
    children: null
};
const clientDeatailsRoutes = {
    id: "Client Details",
    path: "/clients/details/:id",
    icon: < Users / > ,
    component: ClientDetails,
    permission: [],
    children: null
};
const clientProfileRoutes = {
    id: "Client Profile",
    path: "/clients/profile/:id",
    icon: < Users / > ,
    component: ClientProfile,
    permission: [],
    children: null
};
const clientMyInfoRoutes = {
    id: "Clients Add",
    path: "/clients/my-info",
    icon: < Users / > ,
    component: ClientMyInfo,
    permission: [],
    children: null
};

const referralListRoutes = {
    id: "Referrals",
    path: "/referral/list",
    icon: < UserPlus / > ,
    component: ReferralList,
    children: null,
    permission: [],
    alternatePaths: ['/referral/list', '/referral/add', '/referral/details/']
};
const referralAddRoutes = {
    id: "Referrals Add",
    path: "/referral/add",
    icon: <  UserPlus / > ,
    component: ReferralAdd,
    children: null,
    permission: [],
    alternatePaths: ['/referral/add']
};
const referralDetailsRoutes = {
    id: "Referral Details",
    path: "/referral/details/:str",
    icon: < UserPlus / > ,
    component: ReferralDetails,
    children: null,
    permission: [],
};
const caseListRoutes = {
    id: "Cases",
    path: "/case/list",
    icon: < Briefcase / > ,
    component: CaseList,
    children: null,
    permission: [],
    alternatePaths: ['/case/list', '/case/add', '/case/edit/', '/case/details/']
};
const caseAddRoutes = {
    id: "Case Add",
    path: "/case/add",
    icon: < Briefcase / > ,
    component: CaseAdd,
    children: null,
    permission: [],
    alternatePaths: ['/case/add']
};

const caseEditRoutes = {
    id: "Case Edit",
    path: "/case/edit/:id",
    icon: < Briefcase / > ,
    component: CaseAdd,
    children: null,
    permission: [],
};
const caseDetailsRoutes = {
    id: "Case Edit",
    path: "/case/details/:id",
    icon: < Briefcase / > ,
    component: CaseDetails,
    children: null,
    permission: [],
};

const organizationRoutes = {
    id: "Organization",
    path: "/organization",
    icon: < Flag / > ,
    permission: ["organization:list", "service:list"],
    children: [{
            path: "/organization/list",
            name: "All Organizations",
            component: OrganizationList,
            permission: ["organization:list"],
            alternatePaths: ['/organization/list', '/organization/add', '/organization/edit/']
        },
        {
            path: "/organization/service/parent-list",
            name: "Parent Service",
            component: ServiceList,
            permission: ["service:list"],
            alternatePaths: ['/organization/service/parent-list', '/organization/service/add-parent', '/organization/service/edit-parent/']
        },
        {
            path: "/organization/service/child-list",
            name: "Child Service",
            component: ServiceList,
            permission: ["service:list"],
            alternatePaths: ['/organization/service/child-list', '/organization/service/add-child', '/organization/service/edit-child/']
        }
    ],
    component: null,
};
const parentServiceAddRoutes = {
    id: "Parent Service Add",
    path: "/organization/service/add-parent",
    icon: < Star / > ,
    component: ServiceAdd,
    permission: ["service:add"],
    children: null
};
const parentServiceEditRoutes = {
    id: "Parent Service Add",
    path: "/organization/service/edit-parent/:id",
    icon: < Star / > ,
    component: ServiceAdd,
    permission: ["service:add"],
    children: null
};
const childServiceAddRoutes = {
    id: "Child Service Add",
    path: "/organization/service/add-child",
    icon: < Star / > ,
    component: ServiceAdd,
    permission: ["service:add"],
    children: null
};
const childServiceEditRoutes = {
    id: "Child Service Add",
    path: "/organization/service/edit-child/:id",
    icon: < Star / > ,
    component: ServiceAdd,
    permission: ["service:add"],
    children: null
};

const eventCalendarRoutes = {
    id: "Calendar",
    path: "/event-calendar",
    icon: < CalendarIcon / > ,
    component: EventCalendar,
    children: null,
    permission: ["event-calendar-only:view"],
    alternatePaths: ['/event-calendar']
};
const eventsRoutes = {
    id: "Events",
    path: "/events",
    icon: < CalendarIcon / > ,
    permission: ["event:list", "event-category:list", "event-calendar:view"],
    children: [{
            path: "/events/list",
            name: "All Events",
            component: EventList,
            permission: ["event:list"],
            alternatePaths: ['/events/list', '/events/add', '/events/edit/']
        },
        {
            path: "/events/category-list",
            name: "Event Categories",
            component: EventCategoryList,
            permission: ["event-category:list"],
            alternatePaths: ['/events/category-list', '/events/category-add', '/events/category-edit/']
        },
        {
            path: "/events/event-calendar",
            name: "Calendar",
            component: EventCalendar,
            permission: ["event-calendar:view"],
            alternatePaths: ['/events/event-calendar']
        }
    ],
    component: null,
};
const eventAddRoutes = {
    id: "Events Add",
    path: "/events/add",
    icon: < CalendarIcon / > ,
    component: EventAdd,
    permission: ["event:add"],
    children: null,
};
const eventEditRoutes = {
    id: "Events Edit",
    path: "/events/edit/:id",
    icon: < CalendarIcon / > ,
    component: EventAdd,
    permission: ["event:add"],
    children: null,
};
const EventDetailsRoutes = {
    id: "Event Details",
    path: "/events/details/:str",
    icon: <  Briefcase / > ,
    component: EventDetails,
    permission: [],
    children: null,
};
const organizationAddRoutes = {
    id: "Organization Add",
    path: "/organization/add",
    icon: < Star / > ,
    component: OrganizationAdd,
    permission: ["organization:add"],
    children: null,
};
const organizationEditRoutes = {
    id: "Organization Add",
    path: "/organization/edit/:id",
    icon: < Star / > ,
    component: OrganizationAdd,
    permission: ["organization:add"],
    children: null,
};
const eventCategoryAddRoutes = {
    id: "Event Category Add",
    path: "/events/category-add",
    icon: < CalendarIcon / > ,
    component: EventCategoryAdd,
    permission: ["event-category:add"],
    children: null,
};
const eventCategoryEditRoutes = {
    id: "Event Category Edit",
    path: "/events/category-edit/:id",
    icon: < CalendarIcon / > ,
    component: EventCategoryAdd,
    permission: ["event-category:add"],
    children: null,
};
const knowledgeBaseRoutes = {
    id: "KB Admin",
    path: "/knowledgebase",
    icon: < Star / > ,
    permission: ["article:list", "article-category:list"],
    children: [{
            path: "/knowledgebase/list",
            name: "All Articles",
            component: KnowledgebaseList,
            permission: ["article:list"],
            alternatePaths: ['/knowledgebase/list', '/knowledgebase/add', '/knowledgebase/edit/']
        },
        {
            path: "/knowledgebase/category-list",
            name: "Article Categories",
            component: KnowledgebaseCategoryList,
            permission: ["article-category:list"],
            alternatePaths: ['/knowledgebase/category-list', '/knowledgebase/category-add', '/knowledgebase/category-edit/']
        }
    ],
    component: null,
};
const knowledgebaseuserRoutes = {
    id: "Knowledge Base",
    path: "/kb",
    icon: < BookOpen / > ,
    permission: ["faqs:view", "resources:view"],
    children: [{
            path: "/kb/faqs",
            name: "FAQs",
            component: FaqsList,
            permission: ["faqs:view"],
            alternatePaths: ['/kb/faqs']
        },
        {
            path: "/kb/resources/",
            name: "Resources",
            component: ResourcesList,
            permission: ["resources:view"],
            alternatePaths: ['/kb/resources', '/kb/resources/:str']
        }
    ],
    component: null,
};
const KnowledgebaseCategoryAddRoutes = {
    id: "Knowledgebase Category Add",
    path: "/knowledgebase/category-add",
    icon: < Briefcase / > ,
    component: KnowledgebaseCategoryAdd,
    permission: ["article-category:add"],
    children: null,
};

const KnowledgebaseCategoryEditRoutes = {
    id: "Knowledgebase Category Edit",
    path: "/knowledgebase/category-edit/:id",
    icon: < Briefcase / > ,
    component: KnowledgebaseCategoryAdd,
    permission: ["article-category:add"],
    children: null,
};
const KnowledgebaseAddRoutes = {
    id: "Knowledgebase Add",
    path: "/knowledgebase/add",
    icon: < Briefcase / > ,
    component: KnowledgebaseAdd,
    permission: ["article:add"],
    children: null,
};
const KnowledgebaseEditRoutes = {
    id: "Knowledgebase Edit",
    path: "/knowledgebase/edit/:id",
    icon: < Briefcase / > ,
    component: KnowledgebaseAdd,
    permission: ["article:add"],
    children: null,
};
const FaqsCategoryRoutes = {
    id: "Faqs Category",
    path: "/kb/faqs/category/:str",
    icon: < Briefcase / > ,
    component: FaqsCategory,
    permission: ["faqs:view"],
    children: null,
};
const FaqsDetailsRoutes = {
    id: "Faqs Details",
    path: "/kb/faqs/details/:str",
    icon: < Briefcase / > ,
    component: FaqsDetails,
    permission: ["faqs:view"],
    children: null,
};
const ResourcesDetailsRoutes = {
    id: "Resources Details",
    path: "/kb/resources/details/:str",
    icon: < Briefcase / > ,
    component: ResourcesDetails,
    permission: [],
    children: null,
};
const ResourcesListByCategoryRoutes = {
    id: "Resources List Category",
    path: "/kb/resources/:str",
    icon: < Briefcase / > ,
    component: ResourcesList,
    permission: [],
    children: null,
};

const memberDirectoryRoutes = {
    id: "Directory",
    path: "/directory",
    icon: < Map / > ,
    component: null,
    permission: ["company-profile:update", "mapping-directory:view", "md-list:view"],
    children: [{
            path: "/directory/company-profile",
            name: "Company Profile",
            component: ComapnyProfile,
            permission: ["company-profile:update"],
            alternatePaths: ['/directory/company-profile']
        },
        {
            path: "/directory/map-view",
            name: "Map View",
            component: Maps,
            permission: ["mapping-directory:view"],
            alternatePaths: ['/directory/map-view']
        },
        {
            path: "/directory/list-view",
            name: "List View",
            component: MapsList,
            permission: ["md-list:view"],
            alternatePaths: ['/directory/list-view']
        }
    ]
};
const fileManagerRoutes = {
    id: "File Manager",
    path: "/file-manager",
    icon: < Folder / > ,
    component: File,
    children: null,
    permission: ["file-manager:view"],
    alternatePaths: ['/file-manager']
};
const logsRoutes = {
    id: "Logs",
    path: "/user-activity-logs",
    icon: < Search / > ,
    component: UserActivityLogs,
    children: null,
    permission: ["logs:view"],
    alternatePaths: ['/user-activity-logs']
};
const trashRoutes = {
    id: "Trash",
    path: "/trash",
    icon: < Trash2 / > ,
    component: Trash,
    children: null,
    permission: ["trash:list"],
    alternatePaths: ['/trash']
};

const authRoutes = {
    id: "Auth",
    path: "/auth",
    icon: < Users / > ,
    children: [{
            path: "/auth/sign-in",
            name: "Sign In",
            component: SignIn,
        },
        {
            path: "/auth/sign-up",
            name: "Sign Up",
            component: SignUp,
        },
        {
            path: "/auth/reset-password",
            name: "Reset Password",
            component: ResetPassword,
        },
        {
            path: "/auth/404",
            name: "404 Page",
            component: Page404,
        },
        {
            path: "/auth/500",
            name: "500 Page",
            component: Page500,
        },
    ],
    component: null,
};


// This route is only visible while signed in
const protectedPageRoutes = {
    id: "Private",
    path: "/private",
    component: ProtectedPage,
    children: null,
    guard: AuthGuard,
};
const companyProfileRoutes = {
    id: "Company Profile",
    path: "/company-profile",
    icon: < Map / > ,
    component: ComapnyProfile,
    children: null,


};
const logoutRoutes = {
    id: "Logout",
    path: "/logout",
    component: LogOff,
    icon: < LogOut / > ,
    children: null,
    permission: [],
    alternatePaths: ['/logout']
};
// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
    indexRoutes,
    dashboardsRoutes,
    clientListRoutes,
    clientAddRoutes,
    clientDeatailsRoutes,
    clientProfileRoutes,
    clientMyInfoRoutes,
    referralListRoutes,
    referralAddRoutes,
    referralDetailsRoutes,
    caseListRoutes,
    caseAddRoutes,
    caseEditRoutes,
    caseDetailsRoutes,
    organizationRoutes,
    organizationAddRoutes,
    organizationEditRoutes,
    parentServiceAddRoutes,
    parentServiceEditRoutes,
    childServiceAddRoutes,
    childServiceEditRoutes,
    memberDirectoryRoutes,
    eventsRoutes,
    eventAddRoutes,
    eventEditRoutes,
    EventDetailsRoutes,
    eventCalendarRoutes,
    knowledgeBaseRoutes,
    knowledgebaseuserRoutes,
    FaqsCategoryRoutes,
    FaqsDetailsRoutes,
    ResourcesDetailsRoutes,
    ResourcesListByCategoryRoutes,
    fileManagerRoutes,
    logsRoutes,
    companyProfileRoutes,
    eventCategoryAddRoutes,
    eventCategoryEditRoutes,
    KnowledgebaseCategoryAddRoutes,
    KnowledgebaseCategoryEditRoutes,
    KnowledgebaseAddRoutes,
    KnowledgebaseEditRoutes,
    trashRoutes,

    logoutRoutes
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];


// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
    dashboardsRoutes,
    clientListRoutes,
    referralListRoutes,
    caseListRoutes,
    organizationRoutes,
    memberDirectoryRoutes,
    eventsRoutes,
    eventCalendarRoutes,
    knowledgeBaseRoutes,
    knowledgebaseuserRoutes,
    fileManagerRoutes,
    logsRoutes,
    trashRoutes,
    logoutRoutes,


];