import React from "react";
import styled from "styled-components/macro";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Helmet from "react-helmet";
import Loader from "../../components/Loader";
import { Typography } from "@material-ui/core";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;
  min-height:400px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function Page403() {
  return (
    <Wrapper>
      <Helmet title="403 Not authorized" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        403
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Not authorized.
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        You are not authorized of this page.
      </Typography>
    </Wrapper>
  );
}

export default withAuthenticationRequired(Page403, {
  onRedirecting: () => <Loader />,
});